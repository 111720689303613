import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './styles/index.scss'
import 'vant/lib/index.css'
import globalComponent from './components/index'                      //引入全局组件
import { 
  Tabbar,TabbarItem,NavBar,Swipe,SwipeItem,Icon,Button,Form,Field,CellGroup,Checkbox,CheckboxGroup,Dialog,
  Uploader,Picker,Popup,AddressList,AddressEdit,Area   
} from 'vant';

const app = createApp(App)

const vantComponents = {
  router,globalComponent,
  Tabbar,TabbarItem,NavBar,Swipe,SwipeItem,Icon,Button,Form,Field,CellGroup,Checkbox,CheckboxGroup,Dialog,
  Uploader,Picker,Popup,AddressList,AddressEdit,Area         
}

Object.keys(vantComponents).forEach((key) => {          
  app.use(vantComponents[key])
})

app.mount('#app')

// window.addEventListener('storage', (e) => {
//   localStorage.setItem(e.key, e.oldValue);
// });

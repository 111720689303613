import { createRouter, createWebHistory } from 'vue-router';
import routes from './router'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!localStorage.getItem('TOKEN')) {
    if (to.name == "login" || to.name == "register" || to.name == "agreement" || to.name == "policy" || to.name == "carWash") {
      next();
    } else {
      router.push('/login')
    }
  } else {
    if (to.name != "login" && to.name != "register") {
      next()
    } else  {
      router.push('/')
    }
  }
})

export default router;

<template>
  <div class="top">
    <van-nav-bar
      :title="props.title"
      :left-text="props.text"
      :left-arrow="props.arrow"
      @click-left="Back"
    />
  </div>
</template>

<script setup>
  import { defineProps } from 'vue'
  import { useRouter } from 'vue-router'

  const $router = useRouter()
  //接受父组件传递的title
  const props = defineProps({
    title:{
      type:String
    },
    text:{
      type:String,
      default:'返回'
    },
    arrow:{
      type:Boolean,
      default:true
    }
  })

  //回退
  const Back = () => {
    if(props.title == '店铺详情'){
      $router.push('/home')
    }else{
      $router.go(-1)
    }
  }
</script>

<style lang="scss" scoped>
  .top{
    width: 100%;
    height: 45px;
    .van-nav-bar{
      max-width: 750px;
      min-width: 300px;
    }
  }
</style>
import TopTabbar from './topTabbar/index.vue'
import Tabbar from './Tabbar/index.vue'

//定义组件数组
const components = { Tabbar,TopTabbar, }  

export default{
  //遍历注册所有组件 
  install(app) {                
    Object.keys(components).forEach((key) => {          
        app.component(key, components[key])
    })
  }
}
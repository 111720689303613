<template>
  <van-tabbar v-model="active" :border="true">
    <van-tabbar-item icon="home-o" @click="goHome">首页</van-tabbar-item>
    <van-tabbar-item icon="user-o" @click="goUser">我的</van-tabbar-item>
  </van-tabbar>
</template>

<script setup>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'

  const props = defineProps(['value'])

  const active = ref(props.value)
  const $router = useRouter()

  const goHome = () => {
    $router.push('/home')
  }
  const goUser = () => {
    $router.push('/user')
  }
</script>

<style lang="scss" scoped>
  .van-tabbar{
    max-width: 750px;
    min-width: 300px;
    border: 0;
    height: 50px;
  }
</style>